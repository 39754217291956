{
	"type": "FeatureCollection",
	"features": [
		{
			"type": "Feature",
			"properties": {
				"id": 1,
				"name": "Maison de la santé"
			},
			"geometry": {
				"coordinates": [
					1.15367,
					49.03021
				],
			"type": "Point"
			}
		}
	]
}
