
export const OIDC_CONFIG = {
  authority: process.env.OIDC_SERVER || "http://localhost:3030/realms/yukaimaps",
  clientId: process.env.OAUTH_CLIENT_ID || "home",
  clientSecret: process.env.OAUTH_CLIENT_SECRET || "Q1rTcyjwzcIkUsoIAxBL2wQ1XOZs40kW",
  redirectUri: process.env.HOME_DEPLOY_URL || "http://127.0.0.1:1234/"
}

export const EDITOR_URL = process.env.EDITOR_URL || "http://127.0.0.1:8080/"

export const APK_INDEX_FILE_URL = process.env.APK_INDEX_FILE_URL || "http://127.0.0.1:7000/index.json"
